<template>
  <div class="header-box">
    <div class="left">
      <div class="logo">
        <img
          class="logo-img"
          src="../../../assets/img/common/logo.png"
          alt=""
        />
      </div>

      <div
        v-for="(item, index) in navList"
        :key="index"
        :class="index == activeIndex ? ' nav active' : 'nav'"
        @click="jumoTo(item, index)"
      >
        {{ item.text }}
      </div>
    </div>

    <div class="right">
      <div class="right-one">
        <img class="qwx-img" src="../../../assets/img/common/qwx.png" alt="" />
      </div>
      <div class="right-two">
        <div class="mobile-btn">
          <img
            class="pnone-img"
            src="../../../assets/img/common/phone.png"
            alt=""
          />
          <div class="mobile">178 0653 8105</div>
        </div>
      </div>
      <div class="right-three">
        <div class="book-btn" @click="openDialog">预约演示</div>
      </div>
    </div>

    <atlDialog ref="altDialog"></atlDialog>
  </div>
</template>

<script>
import atlDialog from "../../../components/atl-Dialog/atlDialog.vue";
export default {
  components: {
    atlDialog,
  },
  name: "HeadBar",
  data() {
    return {
      activeIndex: 0,
      msg: "顶部",
      navList: [
        {
          text: "首页",
          link: "home",
        },
        {
          text: "AI智慧员工",
          link: "consult",
        },
        {
          text: "AI方案咨询",
          link: "solution",
        },
        {
          text: "行业案列",
          link: "case",
        },
      ],
    };
  },
  methods: {
    jumoTo(item, index) {
      this.activeIndex = index;
      this.$router.push({ name: item.link });
    },

    openDialog() {
      this.$refs.altDialog.open();
    },
    closeDialog() {
      this.$refs.altDialog.close();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 120px;
}

.left {
  display: flex;
  align-items: center;
  margin-right: 219px;
}

@media screen and (max-width: 1450px) {
  .left {
    margin-right: 0;
  }
}
.right {
  display: flex;
  align-items: center;
  .qwx-img {
    width: 24px;
    height: 24px;
  }
  .right-two {
    margin: 0 30px 0 10px;

    .mobile-btn {
      .pnone-img {
        width: 24px;
        height: 24px;
      }
      box-sizing: border-box;
      padding: 4px;
      display: flex;
      align-items: center;
      width: 150px;
      height: 32px;
      opacity: 1;
      border-radius: 16px;
      cursor: pointer;
      border: 1px solid rgba(231, 231, 231, 1);

      .mobile {
        margin-left: 7px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(102, 102, 102, 1);
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .book-btn {
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 32px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    width: 100px;
    height: 32px;
    opacity: 1;
    border-radius: 16px;
    background: linear-gradient(
      90deg,
      rgba(195, 151, 255, 1) 0%,
      rgba(169, 131, 239, 1) 100%
    );
  }
}
.logo {
  margin-right: 60px;
  .logo-img {
    width: 120px;
    height: 40px;
  }
}
.nav {
  display: flex;
  margin-right: 50px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(34, 34, 34, 1);
  cursor: pointer;
}
.active {
  color: rgba(170, 132, 240, 1);
}
</style>
